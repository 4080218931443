@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
      font-family: 'Artemus';
      src: local('Artemus'),
           url('./fonts/Artemus.woff') format('woff'),
           url('./fonts/Artemus.ttf') format('ttf');
      font-weight: 700;
    };
  }

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@layer utilities{
    .paused{
        animation-play-state: paused;
    }
}