::-webkit-scrollbar {
  display: none;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(30, 30, 30); 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #000; 
}

